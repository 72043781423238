<template>
	<v-card class="shadow-blue pa30 track-performance">
		<h3 class="ortho-results-title mb-1">{{ title }}</h3>
		<p class="ortho-description ortho-text-gray">{{ subTitle }}</p>

		<div class="d-flex justify-space-around align-center pl-0 pr-0 pl-md-7 pr-md-7 flex-column flex-md-row">
			<div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center divScoreIndications mb-2 mb-md-0">
				<span class="pointScore mr-2" :style="`background: ${colColor}`"></span>
				<p class="mb-0 d-flex flex-column text-right">
					<span>{{ score }}%</span>
					<span>Ton score</span>
				</p>
			</div>
			<div class="d-flex bar-chart-container">
				<VerticalBarChart :data="generateDataForBarChart()" />
			</div>
			<div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center divScoreIndications">
				<p class="mb-0 d-flex flex-column">
					<span>{{ randomNumberMasteryPercentage() }}%</span>
					<span>Moyenne</span>
				</p>
				<span class="pointScore grey ml-2"></span>
			</div>
		</div>
	</v-card>
</template>

<script>
import VerticalBarChart from '@/components/charts/VerticalBarChart.vue';

export default {
	name: 'TrackPerformance',
	components: { VerticalBarChart },
	props: {
		title: String,
		subTitle: String,
		score: Number,
		colColor: String,
	},
	methods: {
		randomNumberMasteryPercentage() {
			const date = new Date();
			if (date.getDate() % 2 == 0) return 70;
			else return 60;
		},
		generateDataForBarChart() {
			return [
				{ x: 'Ton score', y: this.score ? this.score : 0, fillColor: this.colColor },
				{ x: 'Moyenne', y: this.randomNumberMasteryPercentage(), fillColor: '#989FA9' },
			];
		},
	},
};
</script>

<style lang="scss" scoped>
.track-performance {
	.pointScore {
		display: block;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		&.grey {
			background: #989fa9;
		}
	}
	.divScoreIndications > p > span:first-child {
		font-size: 25px;
		font-weight: bold;
	}
	.bar-chart-container {
		position: relative;
	}
	@media only screen and (max-width: 600px) {
		.divScoreIndications > p > span:first-child {
			font-size: 15px;
			font-weight: bold;
		}
		.divScoreIndications > p > span:nth-child(2) {
			font-size: 10px;
		}
	}
}
</style>

<template>
	<ApexChart type="bar" height="300" width="250" :options="chartOptions" :series="series"></ApexChart>
</template>

<script>
import ApexChart from 'vue-apexcharts';

export default {
	name: 'VerticalBarChart',
	components: { ApexChart },
	props: {
		data: Array,
	},
	data() {
		return {
			series: [{ data: this.data }],
			chartOptions: {
				chart: {
					toolbar: { show: false },
				},
				plotOptions: {
					bar: {
						dataLabels: { position: 'top' },
						borderRadius: 10,
						columnWidth: 43, // Number or '80%'
						horizontal: false,
					},
				},
				states: {
					hover: { filter: { type: 'none' } },
					active: { filter: { type: 'darken', value: 1 } },
				},
				dataLabels: {
					enabled: true,
					formatter: (val) => val + '%',
					offsetY: -20,
					style: { fontSize: '13px', colors: [''], fontWeight: 'normal' },
				},
				xaxis: {
					labels: { show: window.innerWidth < 960 },
					axisTicks: { show: false },
				},
				yaxis: {
					min: 0,
					max: 101,
					tickAmount: 5,
					labels: { show: false },
				},
				tooltip: { enabled: false },
			},
		};
	},
};
</script>

<style lang="scss" scoped></style>
